<template>
  <div class="apply">
    <el-card class="box-card">
      <div slot="header" class="box-card-header">
        <h4>Account Application</h4>
        <div>
          <ul class="stepBox">
            <li class="step">
              <span>{{ $t('dc:提交申请') }}</span>
            </li>
            <li class="step1">
              <span>{{ $t('dc:人工审核') }}</span>
            </li>
            <li class="step2">
              <span>{{ $t('dc:邮件确认') }}</span>
            </li>
            <li class="step3">
              <span>{{ $t('dc:申请完成') }}</span>
            </li>
          </ul>
        </div>
      </div>
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        :rules="rules"
        hide-required-asterisk
        label-width="60px"
        label-position="right"
        class="demo-dynamic"
        style="width: 400px; margin: 80px auto 0"
      >
        <el-form-item
          label="Name"
          prop="username"
          :required="true"
          :class="{ marginB58: !showName }"
        >
          <el-input
            :class="{ redFont: !showName }"
            :disabled="editable"
            placeholder="please input your name"
            v-model="dynamicValidateForm.username"
            maxlength="100"
            show-word-limit
          ></el-input>
          <span v-if="showName" class="fontS12">Please input your name as exampled: MurielLiu</span>
        </el-form-item>
        <el-form-item prop="email" label="E-mail" :class="{ marginB58: !showEmail }">
          <el-input
            v-model="dynamicValidateForm.email"
            :disabled="editable"
            placeholder="please input email"
            maxlength="100"
            show-word-limit
          ></el-input>
          <span v-if="showEmail" class="fontS12">xxxxxxx@xxxx.com</span>
        </el-form-item>
        <el-form-item prop="client" label="Client">
          <el-select
            v-model="dynamicValidateForm.clientId"
            :disabled="editable"
            placeholder="please select client"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in client"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item style="text-align:right;" class="margT80">
         
        </el-form-item> -->
      </el-form>
      <div class="boxBtn">
        <el-button class="" @click="onCancel">{{ $t('dc:取消') }}</el-button>
        <el-button class="btn-black" type="info" @click="submitForm('dynamicValidateForm')"
          >Submit</el-button
        >
      </div>
    </el-card>
    <applyDialog
      :visible.sync="showDialog"
      :title="title"
      :content="content"
      @confrom="confrom"
    ></applyDialog>
  </div>
</template>

<script>
import applyDialog from '@/views/dialog/usuallyDialog'
export default {
  name: 'Apply',
  components: {
    applyDialog,
  },
  data() {
    var nameRoute = (rule, value, callback) => {
      if (value == '') {
        this.showName = false
        callback(new Error(rule.message || 'Please input your name as exampled: MurielLiu'))
      } else if (!new RegExp('^[A-Z][a-z]+([A-Z]+[a-z]+)+$').test(value)) {
        this.showName = false
        callback(new Error(rule.message || 'Please input your name as exampled: MurielLiu'))
      } else if (value.length > 100) {
        this.showName = false
        callback(new Error('Only support 100 characters;'))
      } else {
        this.showName = true
        callback()
      }
    }
    var emailRoute = (rule, value, callback) => {
      const mailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
        this.showEmail = false
      } else if (value.length > 100) {
        this.showEmail = false
        callback(new Error('Only support 100 characters'))
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          this.showEmail = true
          callback()
        } else {
          this.showEmail = false
          callback(new Error(this.$t('dc:请按规则输入')))
        }
      }, 100)
    }
    return {
      client: [],
      dynamicValidateForm: {
        username: '',
        email: '',
        clientId: '',
      },
      rules: {
        username: [{ validator: nameRoute, trigger: ['blur'] }],
        email: [{ validator: emailRoute, trigger: 'blur' }],
        clientId: [
          {
            required: true,
            message: 'Client cannot be empty;',
            trigger: ['blur', 'change'],
          },
        ],
      },
      showDialog: false,
      title: 'Submit',
      content: 'Do you confirm to submit this form?',
      showName: true,
      showEmail: true,
      editable: false,
    }
  },
  mounted() {
    this.initEvent()
  },
  methods: {
    initEvent() {
      this.getClient()
    },
    async getClient() {
      const { status, data } = await $Api.common.getAllClientList()
      if (status === this.$RESPONSE_STATUS.SUCCESS) this.client = data
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.showDialog = true
        } else {
          return false
        }
      })
    },
    async confrom() {
      const { status, data, msg } = await $Api.user.register(this.dynamicValidateForm)
      if (status === this.$RESPONSE_STATUS.SUCCESS) this.$router.push({ name: 'login' })
      else if (status === this.$RESPONSE_STATUS.USER_EXIST) this.showDialog = false
      else this.showDialog = false
    },
    onCancel() {
      this.$router.push('/login')
    },
  },
}
</script>

<style lang="scss">
.apply {
  // card 卡片样式
  .box-card {
    width: 800px;
    margin: 8% auto;
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    h4 {
      margin: 0;
      padding: 0;
      font-size: 21px;
      font-weight: 700;
    }
    .el-card__header {
      border: none;
      padding: 16px;
    }
    .el-form-item__label {
      font-size: 14px;
    }
    .redFont .el-input__inner,
    .redFont .el-input__count-inner {
      color: red;
    }
  }

  // .el-form-item--mini.el-form-item{
  //   margin-bottom: 38px;
  // }
  //  .el-form-item--mini .el-form-item__error{
  //    margin-top: 10px;
  //  }
  .password .el-form-item__error {
    line-height: 22px;
    height: 22px;
  }
  // 提示步骤样式
  .stepBox {
    display: flex;
    justify-content: center;
    font-size: 13px;
    span {
      font-size: 14px;
      font-weight: 700;
    }
    .step {
      width: 90px;
      height: 32px;
      text-align: center;
      color: $poc-theme-bg-color;
      background-color: $--color-primary2;
      position: relative;
      line-height: 32px;
      &::after {
        content: ' ';
        height: 0;
        top: 0px;
        right: -14px;
        position: absolute;
        width: 0;
        display: inline-block;
        border-top: 16px solid transparent;
        border-left: 14px solid $--color-primary2;
        border-bottom: 16px solid transparent;
        z-index: 100;
      }
    }

    .step1,
    .step2 {
      width: 90px;
      height: 32px;
      text-align: center;
      color: #181818;
      background-color: #eee;
      position: relative;
      line-height: 32px;
      margin-left: 4px;
      &::before {
        content: ' ';
        height: 0;
        top: 0px;
        left: 0px;
        position: absolute;
        width: 0;
        display: inline-block;
        border-top: 16px solid transparent;
        border-left: 14px solid #fff;
        border-bottom: 16px solid transparent;
        z-index: 9;
      }
      &::after {
        content: ' ';
        height: 0;
        top: 0px;
        right: -14px;
        position: absolute;
        width: 0;
        display: inline-block;
        border-top: 16px solid transparent;
        border-left: 14px solid #eee;
        border-bottom: 16px solid transparent;
        z-index: 99;
      }
    }
    .step3 {
      width: 90px;
      height: 32px;
      text-align: center;
      color: #181818;
      background-color: #eee;
      position: relative;
      line-height: 32px;
      margin-left: 4px;
      &::before {
        content: ' ';
        height: 0;
        top: 0px;
        left: 0px;
        position: absolute;
        width: 0;
        display: inline-block;
        border-top: 16px solid transparent;
        border-left: 14px solid #fff;
        border-bottom: 16px solid transparent;
        z-index: 9;
      }
    }
    .active {
      background-color: $--color-primary;
      color: #fff;
      &::after {
        border-left-color: $--color-primary;
      }
    }
  }
}
.fontS12 {
  line-height: 22px;
  color: #ccc;
  font-size: 12px;
}
.fontS14 {
  font-size: 14px;
}
.fontS18 {
  font-size: 18px;
}
.marginR20 {
  margin-right: 20px;
}
.margT80 {
  text-align: right;
  margin-top: 90px;
}
.boxBtn {
  margin: 80px 16px 16px;
  text-align: right;
}
.el-form .el-form-item--mini.el-form-item.marginB58 {
  margin-bottom: 24px;
}
</style>
